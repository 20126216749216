.container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  max-width: 500px;
  width: 500px;
  min-height: 600px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.language-switcher {
  display: flex;
  justify-content: flex-end;
}

.export-iframe button,
.language-switcher button {
  margin-left: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.export-iframe button:hover,
.language-switcher button:hover {
  background-color: #e0e0e0;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.step {
  flex: 1;
  padding: 10px;
  border-bottom: 2px solid #e0e0e0;
  position: relative;
  white-space: nowrap;
}

.step.active {
  font-weight: bold;
  color: #cbd749; /* orange color */
}

.step::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: #cbd749; /* orange color */
  display: none;
}

.step.active::before {
  display: block;
}

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
}

h2 {
  color: #274f61; /* deep teal color */
}

label {
  display: block;
  margin-bottom: 8px;
  color: #274f61;
}

input[type="text"],
input[type="email"],
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="text"],
input[type="email"] {
  width: calc(100% - 16px);
}

.add-button,
.back-button,
.submit-button,
.next-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: white;
}

.add-button {
  background-color: #5cb85c; /* green color */
}

.add-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.back-button {
  background-color: #d9534f; /* red color */
}

.submit-button,
.next-button {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.submit-button:enabled,
.next-button:enabled {
  background-color: #cbd749; /* orange color */
  cursor: pointer;
}

.selected-parts {
  list-style-type: none;
  padding: 0;
}

.selected-parts li {
  display: flex;
  background-color: #f7f7f7;
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}

.selected-parts li > button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.overview {
  text-align: left;
  margin-bottom: 12px;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.navigation-buttons {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  gap: 10px;
}

.navigation-buttons > button {
  white-space: nowrap;
}

.navigation-buttons > button:last-child {
  width: 100%;
}

.repair-location {
  margin-top: 20px;
}

.repair-location h3 {
  color: #274f61; /* deep teal color */
}

.repair-location label {
  display: block;
  margin-bottom: 8px;
  color: #274f61;
}

.repair-location input {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.repair-location {
  margin-top: 20px;
}

.repair-location h3 {
  color: #274f61; /* deep teal color */
}

.repair-location label {
  display: block;
  margin-bottom: 8px;
  color: #274f61;
}

.repair-location input {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
